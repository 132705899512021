// 统一请求路径前缀在libs/axios.js中修改
import { getRequest, postRequest, putRequest, postBodyRequest, getNoAuthRequest, postNoAuthRequest } from '@/libs/axios';

// 分页获取数据
export const getWorkGroupList = (params) => {
    return postRequest('/busi/workGroup/getByPage', params)
}

// 分页获取数据
export const getWorkGroupList2 = (params) => {
    return postRequest('/busi/workGroup/getAll', params)
}
// 增加
export const addWorkGroup = (params) => {
    return postRequest('/busi/workGroup/insert', params)
}
// 编辑
export const editWorkGroup = (params) => {
    return postRequest('/busi/workGroup/update', params)
}
// 删除
export const deleteWorkGroup = (params) => {
    return postRequest('/busi/workGroup/delByIds', params)
}