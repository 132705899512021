// 统一请求路径前缀在libs/axios.js中修改
import { getRequest, postRequest, putRequest, postBodyRequest, getNoAuthRequest, postNoAuthRequest } from '@/libs/axios';

// 获取案件分享列表
export const getCaseShareList = (params) => {
    return postRequest(`/busi/caseShare/getByAjid`, params)
}

// 编辑案件分享列表
export const editCaseShare = (params) => {
    return postRequest('/busi/caseShare/update', params)
}
