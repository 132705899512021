<template>
  <div class="case-share">
    <!-- Drawer抽屉 -->
    <Drawer :title="title" v-model="visible" width="720" :mask-closable="false" :closable="false">
      <Row>
        <Form ref="form" :model="form" :rules="formValidate" label-position="top">
          <Row :gutter="32">
            <Col span="9">
            <FormItem label="合办律师电话" prop="telno">
              <Input v-model="form.telno" autocomplete="off" :maxlength="11" />
            </FormItem>
            </Col>
            <Col span="9">
            <FormItem label="合办律师姓名" prop="nickname">
              <Input v-model="form.nickname" autocomplete="off" :maxlength="20" />
            </FormItem>
            </Col>
            <Col span="5">
            <FormItem label="  ">
              <Button @click="add" icon="md-add">添加律师</Button>
            </FormItem>
            </Col>
          </Row>
        </Form>
      </Row>
      <Row>
        <Divider />
      </Row>
      <Row>
        <Col span="24">
        <Transfer :titles="['可分享律师','已分享律师']" :data="dataList" :target-keys="targetKeys" :render-format="render"
          :list-style="listStyle" @on-change="handleChange"></Transfer>
        </Col>
      </Row>
      <br />
      <div class="drawer-footer br" v-show="type!='0'">
        <Button :loading="submitLoading" :disabled="!canSubmit" @click="handleSubmit">保存</Button>
        <Button @click="handleClose">关闭</Button>
      </div>
    </Drawer>
    <Modal v-model="modal" @on-ok="modalOk" @on-cancel="modalCancel">
      <p>已经有信息变动，是否要保存？</p>
    </Modal>
  </div>
</template>

<script>
  // 根据你的实际请求api.js位置路径修改
  import {
    getCaseShareList,
    editCaseShare,
  } from "@/api/busi/case-manage/case-share";
    import {
    getWorkGroupList2,
  } from "@/api/busi/group-manage/group";
  import {
    validateMobile2
  } from "@/libs/validate";

  export default {
    name: "case-share",
    components: {},
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      data: {
        type: Object,
      },
    },
    data() {
      return {
        dataList: [],
        targetKeys: [],
        ajid: "",
        listStyle: {
          width: '300px',
          height: '300px'
        },
        visible: this.value,
        title: "案件团队分享",
        canSubmit: false,
        submitLoading: false,
        modal: false,
        // maxHeight: 510,
        form: {
          telno: "",
          nickname: "",
        },
        formExt: {
          ajid: "",
          telno: "",
        },
        formValidate: {
          telno: [{
              required: true,
              message: "不能为空",
              trigger: "blur"
            },
            {
              validator: validateMobile2,
              trigger: "blur"
            }
          ],
          nickname: [{
            required: true,
            message: "不能为空",
            trigger: "blur"
          }],
        },
      };
    },
    methods: {
      init() {},
      handleSubmit() {
        this.formExt.ajid = this.ajid;
        this.formExt.telno = this.targetKeys;
        this.loading = true;
        editCaseShare(this.formExt).then(res => {
          this.loading = false;
          if (res.success) {
            this.$refs.form.resetFields();
            this.$Message.success("保存成功");
            this.visible = false;
            this.canSubmit = false;
          }
        });
      },
      handleClose() {
        if (this.canSubmit == true) {
          this.modal = true;
        } else {
          this.visible = false;
        }

      },
      setCurrentValue(value) {
        if (value === this.visible) {
          return;
        }
        this.ajid = this.data.id;
        this.getDataList();
        this.getShareKeys();
        this.canSubmit = false;
        this.visible = value;
      },
      getDataList() {
        this.loading = true;
        getWorkGroupList2().then(res => {
          this.loading = false;
          if (res.success) {
            var data = [];
            res.result.map((value, index, arry) => {
              data.push({
                'key': value.telno,
                'telno': value.telno,
                'nickname': value.nickname
              });
            });
            this.dataList = data;
          }
        });
      },
      getShareKeys() {
        this.loading = true;
        getCaseShareList({"ajid":this.ajid}).then(res => {
          this.loading = false;
          if (res.success) {
            var data = [];
            res.result.map((value, index, arry) => {
              data.push(value.telno);
            });
            this.targetKeys = data;
          }
        });
      },
      handleChange(newTargetKeys) {
        this.targetKeys = newTargetKeys;
        this.canSubmit = true;
      },
      render(item) {
        return item.telno + '(' + item.nickname + ')';
      },
      add() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            var isExist = this.dataList.filter((p) => {
              return p.key == this.form.telno;
            });
            if (isExist.length > 0) {
              this.$Message.warning("该手机号码律师已存在");
              return;
            }
            editCaseGroup(this.form).then((res) => {
              this.submitLoading = false;
              if (res.success) {
                this.dataList.push({
                  'key': this.form.telno,
                  'telno': this.form.telno,
                  'nickname': this.form.nickname
                });
                this.targetKeys.push(this.form.telno);
                this.canSubmit = true;
                this.$refs.form.resetFields();
                this.$Message.success("操作成功");
              }
            });
          }
        });
      },
      modalOk() {
        this.handleSubmit();
        this.visible = false;
      },
      modalCancel() {
        this.visible = false;
      },
    },
    watch: {
      value(val) {
        this.setCurrentValue(val);
      },
      visible(value) {
        this.$emit("input", value);
      },
    },
    mounted() {
      this.init();
    },
  };
</script>

<style lang="less">
  // @import "../../../styles/table-common.less";
  .drawer-content {
    overflow: auto;
  }

  .drawer-content::-webkit-scrollbar {
    display: none;
  }

  .user-title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .info-title {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      display: block;
      margin-right: 16px;
    }
  }

  .info-header {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    display: block;
    margin-bottom: 16px;
  }
</style>